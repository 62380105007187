<template>
  <BaseDialog
    data-testid="edit-reservation-advance-settings-modal"
    width="566px"
    title="編輯進階服務設定"
    :btn1Loading="loading"
    @close="$emit('close')"
    @confirm="onConfirm"
  >
    <div>
      <BaseElForm
        :model="formData"
        label-position="top"
        @submit.prevent.native="updateReservationParameter"
      >
        <BaseElFormItem label="預約模式" class="mb-[32px]">
          <BaseElRadioGroup v-model="formData.clientOrderMode" testName="formData_clientOrderMode">
            <div class="flex flex-col" style="gap: 20px">
              <BaseElRadio
                v-for="option in [
                  {label: '基礎模式', description: '基本預約模式，一次預約一個服務', value: 'normal'},
                  {label: '多服務模式', description: '可一次預約多個服務，最多一次可預約 4 個服務',value: 'otherReservation'},
                  {label: '多時段模式', description: '可一次預約同個服務的多個時段，最多一次可預約 4 個時段',value: 'batchOrder'},
                ]"
                :key="option.value"
                class="flex items-start"
                :label="option.value"
              >
                <div class="flex flex-col" style="gap: 8px">
                  <p class="text-sub font-medium">{{ option.label }}</p>
                  <p class="text-sm font-normal">{{ option.description }}</p>
                </div>
              </BaseElRadio>
            </div>
          </BaseElRadioGroup>
        </BaseElFormItem>

        <BaseElFormItem label="多人預約單服務">
          <BaseElSwitch
            v-model="formData.allowOrderMorePeople"
            testName="formData_allowOrderMorePeople"
            class="switch-input"
            active-text="開啟"
            inactive-text="關閉"
          />
        </BaseElFormItem>

        <BaseElFormItem
          v-if="formData.allowOrderMorePeople"
          label="多人預約人數上限"
        >
          <BaseElInputNumber
            v-model="formData.maxOrderMorePeopleCount"
            testName="formData_maxOrderMorePeopleCount"
            placeholder="請輸入人數"
            :min="1"
            :max="10"
          />
        </BaseElFormItem>

        <template v-if="['otherReservation'].includes(formData.clientOrderMode)">
          <!-- <BaseElFormItem label="單人預約多服務">
            <BaseElSwitch
              v-model="formData.allowOtherReservation"
              data-cy="allow-order-other-service-switch"
              class="switch-input"
              active-text="開啟"
              inactive-text="關閉"
            />
          </BaseElFormItem> -->

          <template>
            <BaseElFormItem label="其他服務預設開始時間設定">
              <GroupOptionsButton
                testName="formData_secondOrderStartDefault"
                :model.sync="formData.secondOrderStartDefault"
                :options="[
                  {label: '接續第一個服務', value: 'continue'},
                  {label: '與第一個服務同時', value: 'same'},
                ]"
              />
            </BaseElFormItem>
            <!-- <BaseElFormItem>
                <template slot="label">
                  <div class="flex items-center">
                    <span style="margin-right: 2px">是否開放客人自選其他服務時間</span>
                    <el-tooltip placement="right">
                      <div slot="content">
                        開放自選時，系統會自動預設時間，而客人可根據需求自行選擇其他時間選項<br>
                        關閉自選時，系統會自動預設時間，而客人不可更改時間選項
                      </div>
                      <span class="material-icons">
                        help_outline
                      </span>
                    </el-tooltip>
                  </div>
                </template>
                <BaseElSwitch
                  v-model="formData.secondOrderStartClientChange"
                  active-text="開啟"
                  inactive-text="關閉"
                  @change="updateReservationParameter"
                />
              </BaseElFormItem> -->
          </template>
        </template>
      </BaseElForm>
    </div>
  </BaseDialog>
</template>

<script>
import { defineComponent, onBeforeMount } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useBaseForm } from '@/use/useForm'
import { UpdateReservationParameter } from '@/api/reservation'
import GroupOptionsButton from '@/components/Button/GroupOptionsButton.vue'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'EditReservationAdvanceSettings',
  components: {
    BaseDialog,
    GroupOptionsButton,
  },
  props: {
    configData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { formRef, formData, loading, initFormData } = useBaseForm()

    initFormData({
      clientOrderMode: 'normal', // normal | otherReservation | batchOrder
      allowBatchOrder: false,
      allowOrderMorePeople: false,
      maxOrderMorePeopleCount: 1,
      allowOtherReservation: false,
      secondOrderStartDefault: 'continue',
      secondOrderStartClientChange: false,
    })

    const updateReservationParameter = async () => {
      if (loading.value) return
      loading.value = true

      const [, err] = await UpdateReservationParameter({
        shopId: shopId.value,
        clientOrderMode: formData.clientOrderMode,
        allowOrderMorePeople: formData.allowOrderMorePeople,
        maxOrderMorePeopleCount: formData.maxOrderMorePeopleCount || 1,
        // allowOtherReservation: formData.allowOtherReservation,
        // secondOrderStartClientChange: formData.secondOrderStartClientChange,
        secondOrderStartDefault: formData.secondOrderStartDefault,
        // allowBatchOrder: formData.allowBatchOrder,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功!')
      emit('refresh')
      emit('close')
    }

    const syncFormData = () => {
      const config = { ...props.configData }
      formData.clientOrderMode = config.clientOrderMode
      // formData.allowBatchOrder = config.allowBatchOrder
      formData.allowOrderMorePeople = config.allowOrderMorePeople
      formData.maxOrderMorePeopleCount = config.maxOrderMorePeopleCount || 1
      formData.allowOtherReservation = config.allowOtherReservation
      formData.secondOrderStartDefault = config.secondOrderStartDefault || 'continue'
      formData.secondOrderStartClientChange = config.secondOrderStartClientChange
    }

    const onConfirm = () => {
      updateReservationParameter()
    }
    onBeforeMount(() => {
      syncFormData()
    })

    return { onConfirm, formRef, formData, loading, updateReservationParameter }
  },
})

</script>

<style lang="postcss" scoped>
.area-disabled {
  @apply pointer-events-none opacity-50;
  filter: grayscale(1);
}
</style>
