<template>
  <el-dialog
    title="編輯預約確認設定"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <AiHintBlock
      :hintLoading="hintLoading"
      @confirm="hintConfirm"
    />
    <BaseElForm
      ref="form"
      v-loading="hintLoading"
      label-position="top"
      :model="formData"
      :rules="formRules"
    >
      <BaseElFormItem label="是否開啟預約確認提示：" prop="enable">
        <BaseElSwitch
          v-model="formData.enable"
          active-text="開啟"
          inactive-text="關閉"
          @change="enableChange"
        />
      </BaseElFormItem>
      <BaseElFormItem label="標題名稱" prop="title">
        <BaseElInput
          v-model="formData.title"
          placeholder="請輸入"
          :disabled="!formData.enable"
          maxlength="12"
          show-word-limit
        />
      </BaseElFormItem>
      <BaseElFormItem ref="content" label="內容" prop="content">
        <div :class="{'editor-diabled': !formData.enable}">
          <quillEditor
            v-model="formData.content"
            :disabled="!formData.enable"
            :options="editorOption"
          />
        </div>
      </BaseElFormItem>
    </BaseElForm>
    <div slot="footer">
      <BaseElButton plain @click="$emit('close')">取消</BaseElButton>
      <BaseElButton
        ref="createBtn"
        type="primary"
        @click="handleUpdate"
      >
        確認
      </BaseElButton>
    </div>
  </el-dialog>
</template>

<script>
import { noEmptyRules } from '@/validation'
import quillMixin from '@/mixin/quill'
import { UpdateAppointmentCreatePromptPage } from '@/api/reservation'
import { mapGetters } from 'vuex'
import formUtils from '@/utils/form'
import { get } from 'lodash'
import AiHintBlock from '@/components/AiHintBlock.vue'
import { useAi } from '@/use/useAi'
import { hint } from '@/config/hint'
import { defineComponent, reactive } from 'vue'

export default defineComponent({
  name: 'EditNoticeSettingsDialog',
  components: {
    AiHintBlock,
  },
  mixins: [quillMixin],
  props: {
    configData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup () {
    const { configurationHint, hintLoading, setData } = useAi()
    const formData = reactive({
      enable: false,
      title: '',
      content: '',
    })
    const hintConfirm = async (payload) => {
      const res = await configurationHint(get(hint, 'appointmentCreatePromptPages.key'), payload)
      if (!res) {
        hintLoading.value = false
        return
      }
      setData(formData, res)
    }
    return { hintLoading, hintConfirm, formData }
  },
  data: () => ({
    imageTemp: null,
    dialog: {
      cropper: false,
    },
    // formData: {
    //   enable: false,
    //   title: '',
    //   content: '',
    // },
  }),
  computed: {
    ...mapGetters(['shop']),
    formRules () {
      if (!this.formData.enable) {
        return {
          title: [noEmptyRules()],
        }
      }
      return {
        title: [noEmptyRules()],
        content: [noEmptyRules()],
      }
    },
  },
  mounted () {
    this.syncFormData()
  },
  methods: {
    enableChange (e) {
      if (!e) this.$refs.content.clearValidate()
    },
    async handleUpdate () {
      if (!await formUtils.checkForm(this.$refs.form)) return
      if (!await this.updateAppointmentCreatePromptPage()) return
      this.$message.success('更新成功！')
      this.$emit('refresh')
      this.$emit('close')
    },
    syncFormData () {
      this.formData.enable = this.configData.enable
      this.formData.title = get(this.configData, 'title') || null
      this.formData.content = get(this.configData, 'content') || null
    },
    async updateAppointmentCreatePromptPage () {
      const form = this.formData
      const [, err] = await UpdateAppointmentCreatePromptPage({
        shopId: this.shop,
        enable: form.enable,
        title: form.title || undefined,
        content: form.content || '',
      })
      if (err) {
        this.$message.error(err)
        return false
      }
      return true
    },
  },
})
</script>

<style lang="postcss" scoped>
.editor-diabled {
  @apply bg-info-light opacity-50 cursor-not-allowed pointer-events-none;
}
</style>
