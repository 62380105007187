<template>
  <el-dialog
    title="編輯預約參數"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <BaseElForm ref="formRef" :model="formData" :rules="formRules" label-position="top">
      <BaseElFormItem label="時段間隔(分鐘)" prop="timeUnit">
        <BaseElInput v-model="formData.timeUnit" testName="formData_timeUnit" />
      </BaseElFormItem>

      <BaseElFormItem label="時段偏移(分鐘)" prop="timeUnitOffset">
        <BaseElInput v-model="formData.timeUnitOffset" testName="formData_timeUnitOffset" />
      </BaseElFormItem>

      <BaseElFormItem label="最小預約時數(分鐘)" prop="minReservationInterval">
        <BaseElInput v-model="formData.minReservationInterval" testName="formData_minReservationInterval" />
      </BaseElFormItem>

      <BaseElFormItem label="最大預約時數(分鐘)" prop="maxReservationInterval">
        <BaseElInput v-model="formData.maxReservationInterval" testName="formData_maxReservationInterval" />
      </BaseElFormItem>

      <BaseElFormItem label="最晚可取消預約時間(分鐘)" prop="cancelInterval">
        <BaseElInput v-model="formData.cancelInterval" testName="formData_cancelInterval" />
      </BaseElFormItem>
    </BaseElForm>

    <div slot="footer">
      <BaseElButton plain :loading="loading" @click="$emit('close')">取消</BaseElButton>
      <BaseElButton type="primary" data-cy="update-time-parameter" @click="onSubmit"> 儲存 </BaseElButton>
    </div>
  </el-dialog>
</template>

<script>
import { UpdateReservationParameter } from '@/api/reservation'
import { useBaseForm } from '@/use/useForm'
import { computed, defineComponent, onBeforeMount } from 'vue'
import { useShop } from '@/use/shop'
import notifyMessage from '@/config/notifyMessage'
import { isDigitRules, noEmptyRules, rangeRules } from '@/validation'

export default defineComponent({
  name: 'EditReservationBasicSettingsModal',
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const { formRef, initFormData, formData, checkForm, loading } = useBaseForm()
    const { shopId } = useShop()

    const formRules = computed(() => {
      return {
        timeUnit: [isDigitRules(), noEmptyRules(), rangeRules(10)],
        timeUnitOffset: [noEmptyRules(), isDigitRules(), rangeRules()],
        minReservationInterval: [noEmptyRules(), isDigitRules(), rangeRules(-1, 525600)],
        maxReservationInterval: [noEmptyRules(), isDigitRules(), rangeRules(-1, 525600)],
        cancelInterval: [isDigitRules(), rangeRules(0, 9999999)],
      }
    })

    const onSubmit = async () => {
      if (loading.value) return
      loading.value = true
      if (!(await checkForm(formRef.value))) return
      const [, err] = await UpdateReservationParameter({
        shopId: shopId.value,
        ...props.configData,
        timeUnit: formData.timeUnit,
        timeUnitOffset: formData.timeUnitOffset,
        minReservationInterval: formData.minReservationInterval,
        maxReservationInterval: formData.maxReservationInterval,
        cancelInterval: formData.cancelInterval,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }

      window.$message.success(notifyMessage.updateSuccess)
      emit('close')
      emit('refresh')
    }

    onBeforeMount(() => {
      initFormData({
        timeUnit: props.configData.timeUnit,
        timeUnitOffset: props.configData.timeUnitOffset,
        minReservationInterval: props.configData.minReservationInterval,
        maxReservationInterval: props.configData.maxReservationInterval,
        cancelInterval: props.configData.cancelInterval,
      })
    })

    return { formRef, onSubmit, loading, formRules, formData }
  },
})
</script>

<style lang="postcss" scoped></style>
