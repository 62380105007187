var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('BaseDialog',{attrs:{"data-testid":"edit-reservation-advance-settings-modal","width":"566px","title":"編輯進階服務設定","btn1Loading":_vm.loading},on:{"close":function($event){return _vm.$emit('close')},"confirm":_vm.onConfirm}},[_c('div',[_c('BaseElForm',{attrs:{"model":_vm.formData,"label-position":"top"},nativeOn:{"submit":function($event){$event.preventDefault();return _vm.updateReservationParameter.apply(null, arguments)}}},[_c('BaseElFormItem',{staticClass:"mb-[32px]",attrs:{"label":"預約模式"}},[_c('BaseElRadioGroup',{attrs:{"testName":"formData_clientOrderMode"},model:{value:(_vm.formData.clientOrderMode),callback:function ($$v) {_vm.$set(_vm.formData, "clientOrderMode", $$v)},expression:"formData.clientOrderMode"}},[_c('div',{staticClass:"flex flex-col",staticStyle:{"gap":"20px"}},_vm._l(([
                {label: '基礎模式', description: '基本預約模式，一次預約一個服務', value: 'normal'},
                {label: '多服務模式', description: '可一次預約多個服務，最多一次可預約 4 個服務',value: 'otherReservation'},
                {label: '多時段模式', description: '可一次預約同個服務的多個時段，最多一次可預約 4 個時段',value: 'batchOrder'},
              ]),function(option){return _c('BaseElRadio',{key:option.value,staticClass:"flex items-start",attrs:{"label":option.value}},[_c('div',{staticClass:"flex flex-col",staticStyle:{"gap":"8px"}},[_c('p',{staticClass:"text-sub font-medium"},[_vm._v(_vm._s(option.label))]),_c('p',{staticClass:"text-sm font-normal"},[_vm._v(_vm._s(option.description))])])])}),1)])],1),_c('BaseElFormItem',{attrs:{"label":"多人預約單服務"}},[_c('BaseElSwitch',{staticClass:"switch-input",attrs:{"testName":"formData_allowOrderMorePeople","active-text":"開啟","inactive-text":"關閉"},model:{value:(_vm.formData.allowOrderMorePeople),callback:function ($$v) {_vm.$set(_vm.formData, "allowOrderMorePeople", $$v)},expression:"formData.allowOrderMorePeople"}})],1),(_vm.formData.allowOrderMorePeople)?_c('BaseElFormItem',{attrs:{"label":"多人預約人數上限"}},[_c('BaseElInputNumber',{attrs:{"testName":"formData_maxOrderMorePeopleCount","placeholder":"請輸入人數","min":1,"max":10},model:{value:(_vm.formData.maxOrderMorePeopleCount),callback:function ($$v) {_vm.$set(_vm.formData, "maxOrderMorePeopleCount", $$v)},expression:"formData.maxOrderMorePeopleCount"}})],1):_vm._e(),(['otherReservation'].includes(_vm.formData.clientOrderMode))?[[_c('BaseElFormItem',{attrs:{"label":"其他服務預設開始時間設定"}},[_c('GroupOptionsButton',{attrs:{"testName":"formData_secondOrderStartDefault","model":_vm.formData.secondOrderStartDefault,"options":[
                {label: '接續第一個服務', value: 'continue'},
                {label: '與第一個服務同時', value: 'same'},
              ]},on:{"update:model":function($event){return _vm.$set(_vm.formData, "secondOrderStartDefault", $event)}}})],1)]]:_vm._e()],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }