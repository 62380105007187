<template>
  <section class="settings-section section-block">
    <SectionTitle
      cyBtn="edit-time-parameter"
      title="時段設定"
      btn="編輯"
      @edit="modal.edit = true"
    />
    <div>
      <BaseElForm label-position="left" label-width="250px">
        <BaseElFormItem class="settings-item">
          <template slot="label">
            <div class="flex items-center" style="gap: 8px">
              <p>時段間隔(分鐘)</p>
              <TipInfo :size="16" width="200">
                <p>設定兩個預約時間的間隔，例如從 10:00 開始營業，時段間隔 60 分鐘，則客人可預約的時間即為 10:00、11:00、12:00...，以此類推。</p>
              </TipInfo>：
            </div>
          </template>
          <span class="content">{{
            configData.timeUnit !== null ? configData.timeUnit : '尚未設定'
          }}</span>
        </BaseElFormItem>

        <BaseElFormItem class="settings-item">
          <template slot="label">
            <div class="flex items-center" style="gap: 8px">
              <p>時段偏移(分鐘)</p>
              <TipInfo :size="16" width="200">
                <p>設定時段的偏移，例如最早排班時間為 10:00，但 10:05 才開始服務客人，就可以在時段偏移設定 5 分鐘，客人可以看到的時間就是 10:05。</p>
              </TipInfo>：
            </div>
          </template>
          <span class="content">{{
            configData.timeUnitOffset !== null ? configData.timeUnitOffset : '尚未設定'
          }}</span>
        </BaseElFormItem>

        <BaseElFormItem class="settings-item">
          <template slot="label">
            <div class="flex items-center" style="gap: 8px">
              <p>最小預約時數(分鐘)</p>
              <TipInfo :size="16" width="200">
                <p>服務的準備時間要多久、能不能即時預約，必須在服務開始多久前預約，例如 1/10 10:00 服務，設定 1440 分鐘（24小時)，則服務最晚要在 1/9 10:00 前被預約，1/9 10:01 以後客人就預約不到 1/10 10:00 以前的服務。</p>
              </TipInfo>：
            </div>
          </template>
          <span class="content">{{
            configData.minReservationInterval !== null
              ? configData.minReservationInterval
              : '尚未設定'
          }}</span>
        </BaseElFormItem>

        <BaseElFormItem class="settings-item">
          <template slot="label">
            <div class="flex items-center" style="gap: 8px">
              <p>最大預約時數(分鐘)</p>
              <TipInfo :size="16" width="200">
                <p>服務最早可以在什麼時候開始被預約、客人可以預約到多久以後的預約，例如僅能在服務前 3 天預約，就設定 4320 分鐘。若無限制輸入 -1 即可。</p>
              </TipInfo>：
            </div>
          </template>
          <span class="content">{{
            configData.maxReservationInterval ? configData.maxReservationInterval : '尚未設定'
          }}</span>
        </BaseElFormItem>

        <BaseElFormItem class="settings-item">
          <template slot="label">
            <div class="flex items-center" style="gap: 8px">
              <p>最晚可取消預約時間(分鐘)</p>
              <TipInfo :size="16" width="200">
                <p>預約後最晚在服務多久前可以取消，例如設定 1440 分鐘（24小時），有預約是 1/10 10:00，則該服務必須在 1/9 10:00 前被取消，超過後客人就無法取消。</p>
              </TipInfo>：
            </div>
          </template>
          <span class="content">{{
            configData.cancelInterval ? configData.cancelInterval : '尚未設定'
          }}</span>
        </BaseElFormItem>
      </BaseElForm>
    </div>

    <EditReservationBasicSettingsModal
      v-if="modal.edit"
      data-testid="edit-reservation-basic-settings-modal"
      :configData="configData"
      @close="modal.edit = false"
      @refresh="$emit('refresh')"
    />
  </section>
</template>

<script>
import { defineComponent, reactive } from 'vue'
import EditReservationBasicSettingsModal from './EditReservationBasicSettingsModal.vue'
import TipInfo from '@/components/TipInfo.vue'
export default defineComponent({
  name: 'ReservationBasicSettings',
  components: {
    EditReservationBasicSettingsModal,
    TipInfo,
  },
  props: {
    configData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const modal = reactive({
      edit: false,
    })
    return { modal }
  },
})
</script>

<style lang="postcss" scoped></style>
