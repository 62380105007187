<template>
  <section class="section-block">
    <SectionTitle title="預約須知設定" @edit="dialog.edit = true" />
    <BaseElForm label-position="left" label-width="250px">
      <BaseElFormItem label="是否開啟預約須知：">
        <span class="text-info">{{ configData.enable ? '開啟' : '關閉' }}</span>
      </BaseElFormItem>
      <BaseElFormItem label="標題名稱：">
        <span class="text-info">{{ configData.title || '尚未設定' }}</span>
      </BaseElFormItem>
      <BaseElFormItem label="圖片：">
        <span class="text-info">{{ images }}</span>
      </BaseElFormItem>
      <BaseElFormItem label="內容：">
        <span class="text-info">{{ content }}</span>
      </BaseElFormItem>
    </BaseElForm>

    <EditNoticeSettingsDialog
      v-if="dialog.edit"
      :configData="configData"
      @refresh="refresh"
      @close="dialog.edit = false"
    />
  </section>
</template>

<script>
import EditNoticeSettingsDialog from './EditNoticeSettingsDialog.vue'
import { GetAppointmentHomePage } from '@/api/reservation'
import { mapGetters } from 'vuex'
import { get } from 'lodash'

export default {
  name: 'NoticeSettings',
  components: { EditNoticeSettingsDialog },
  data: () => ({
    dialog: {
      edit: false,
    },
    configData: {},
  }),
  computed: {
    ...mapGetters(['shop']),
    content () {
      if (!get(this.configData, 'content')) return '尚未設定'
      return '已設置'
    },
    images () {
      const images = get(this.configData, 'Images')
      if (!images) return '尚未設定'
      if (!images.length) return '尚未設定'
      return '已設置'
    },
  },
  async mounted () {
    await this.refresh()
  },
  methods: {
    async refresh () {
      await this.getAppointmentHomePage()
    },

    async getAppointmentHomePage () {
      const [res, err] = await GetAppointmentHomePage({ shopId: this.shop })
      if (err) {
        this.$message.error(err)
        return
      }
      this.configData = res
    },
  },
}
</script>

<style lang="postcss" scoped>
</style>
