<template>
  <el-dialog
    title="編輯不指定人員設定"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <BaseElForm ref="formRef" :model="formData" :rules="formRules" label-position="top">
      <BaseElFormItem label="不指定人員狀態 ：" class="settings-item" prop="useNotSpecify">
        <BaseElSwitch
          v-model="formData.useNotSpecify"
          testName="formData_useNotSpecify"
          class="switch-input"
          active-text="開啟"
          inactive-text="關閉"
        />
      </BaseElFormItem>

      <BaseElFormItem label="跳過選擇服務人員流程 ：" class="settings-item" prop="notSpecifySkipUnit">
        <BaseElSwitch
          v-model="formData.notSpecifySkipUnit"
          testName="formData_notSpecifySkipUnit"
          :disabled="!formData.useNotSpecify"
          class="switch-input"
          active-text="開啟"
          inactive-text="關閉"
        />
      </BaseElFormItem>

      <BaseElFormItem>
        <template slot="label">
          <FormItemTooltipLabel label="不指定人員排序" :tooltipWidth="200">
            在客人端預約時，「不指定人員」選項顯示於服務人員首位 / 末位
          </FormItemTooltipLabel>
        </template>

        <BaseElSelect v-model="formData.notSpecifyOrderRule" testName="formData_notSpecifyOrderRule">
          <BaseElSelectOption
            v-for="option in noSpecifyUnitOrderOptionConfig"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          />
        </BaseElSelect>
      </BaseElFormItem>

      <BaseElFormItem label="不指定人員名稱 ：" class="settings-item" prop="notSpecifyName">
        <BaseElInput
          v-model="formData.notSpecifyName"
          testName="formData_notSpecifyName"
          :disabled="!formData.useNotSpecify"
          placeholder="請輸入"
        />
      </BaseElFormItem>

      <BaseElFormItem label="不指定人員照片  ：" class="settings-item" prop="NotSpecifyImage">
        <UploadButton
          cyUploadBtn="nospecify-unit-img-upload"
          cyUploadedImg="nospecify-unit-img-uploaded"
          :disabled="!formData.useNotSpecify"
          style="width: 150px; height: 150px"
          :img="formData.NotSpecifyImage"
          @change="imageChange"
        />
        <!-- <img  v-if="image" :src="imgUrl(image)" alt="" > -->
      </BaseElFormItem>

      <BaseElFormItem label="不指定人員綁定  ：" class="settings-item" prop="NotSpecifyUnits">
        <ServicesUnitSelect
          testName="formData_NotSpecifyUnits"
          :disabled="!formData.useNotSpecify"
          :model.sync="formData.NotSpecifyUnits"
          multiple
        />
      </BaseElFormItem>

      <BaseElFormItem label="同時空檔人員分配規則  ：" class="settings-item" prop="notSpecifyRule">
        <BaseElSelect
          v-model="formData.notSpecifyRule"
          testName="formData_notSpecifyRule"
          :disabled="!formData.useNotSpecify"
          no-data-text="暫無數據"
          placeholder="請選擇規則"
          clearable
        >
          <BaseElSelectOption
            v-for="rule in notSpecifyRules"
            :key="rule.id"
            :label="rule.name"
            :value="rule.id"
          />
        </BaseElSelect>
      </BaseElFormItem>

      <p>預約數優先：依照前後14天內預約數，較低者優先，相同則隨機。</p>
      <p>不指定預約數優先：依照前後14天內來自不指定的預約數，較低者優先，相同則隨機。</p>
      <p>排序優先：依照服務人員的排序，較低者優先。</p>
    </BaseElForm>

    <div slot="footer">
      <BaseElButton plain @click="$emit('close')">取消</BaseElButton>
      <BaseElButton
        :lodading="loading"
        data-cy="edit-notspecify-dialog-confirm-btn"
        type="primary"
        @click="onSubmit"
      >
        儲存
      </BaseElButton>
    </div>

    <ImageCropper
      v-if="uploadDialog"
      :image="formData.NotSpecifyImage"
      @close="uploadDialog = false"
      @uploaded="getImage"
    />
  </el-dialog>
</template>

<script>
import { computed, defineComponent, onBeforeMount, ref } from 'vue'
import { useBaseForm } from '@/use/useForm'
import { noEmptyRules } from '@/validation'
import { noSpecifyUnitOrderOptionConfig } from '@/config/reservation'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import ServicesUnitSelect from '@/components/Select/ServicesUnitSelect.vue'
import notifyMessage from '@/config/notifyMessage'
import { UpdateReservationParameter } from '@/api/reservation'
import { useShop } from '@/use/shop'
import { get, map } from 'lodash'

export default defineComponent({
  name: 'EditReservationNotspecifySettingsModal',
  components: { FormItemTooltipLabel, UploadButton, ServicesUnitSelect, ImageCropper },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const uploadDialog = ref(false)
    const { shopId } = useShop()
    const { formRef, formData, initFormData, loading, checkForm } = useBaseForm()

    const notSpecifyRules = {
      order: { id: 'order', name: '按排序大小' },
      reservation: { id: 'reservation', name: '預約數分配' },
      notSpecifyReservation: {
        id: 'notSpecifyReservation',
        name: '不指定預約數分配',
      },
      random: { id: 'random', name: '隨機' },
    }

    const formRules = computed(() => {
      return {
        useNotSpecify: [noEmptyRules()],
        notSpecifySkipUnit: [noEmptyRules()],
        notSpecifyName: [noEmptyRules()],
        NotSpecifyImage: [noEmptyRules()],
        notSpecifyRule: [noEmptyRules()],
        NotSpecifyUnits: formData.useNotSpecify ? [noEmptyRules()] : [],
      }
    })

    const imageChange = (img) => {
      formData.NotSpecifyImage = img
      uploadDialog.value = true
    }

    const getImage = (img) => {
      formData.NotSpecifyImage = img
      uploadDialog.value = false
    }

    const onSubmit = async () => {
      if (loading.value) return
      loading.value = true
      if (!(await checkForm(formRef.value))) return
      const [, err] = await UpdateReservationParameter({
        shopId: shopId.value,
        ...props.configData,
        useNotSpecify: formData.useNotSpecify,
        notSpecifySkipUnit: formData.notSpecifySkipUnit,
        notSpecifyOrderRule: formData.notSpecifyOrderRule,
        notSpecifyName: formData.notSpecifyName,
        notSpecifyImage: get(formData.NotSpecifyImage, 'id'),
        notSpecifyUnits: map(formData.NotSpecifyUnits, 'id'),
        notSpecifyRule: formData.notSpecifyRule,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }

      window.$message.success(notifyMessage.updateSuccess)
      emit('close')
      emit('refresh')
    }

    onBeforeMount(() => {
      initFormData({
        useNotSpecify: props.configData.useNotSpecify,
        notSpecifySkipUnit: props.configData.notSpecifySkipUnit,
        notSpecifyOrderRule: props.configData.notSpecifyOrderRule,
        notSpecifyName: props.configData.notSpecifyName,
        NotSpecifyImage: props.configData.NotSpecifyImage,
        NotSpecifyUnits: props.configData.NotSpecifyUnits,
        notSpecifyRule: props.configData.notSpecifyRule,
      })
    })

    return {
      formRef,
      formData,
      loading,
      notSpecifyRules,
      formRules,
      noSpecifyUnitOrderOptionConfig,
      uploadDialog,
      imageChange,
      getImage,
      onSubmit,
    }
  },
})
</script>

<style lang="postcss" scoped></style>
