<template>
  <section class="section-block settings-section">
    <SectionTitle
      cyBtn="edit-notspecify-parameter"
      title="不指定人員設定"
      btn="編輯"
      @edit="modal.edit = true"
    />
    <div>
      <BaseElForm label-position="left" label-width="250px">
        <BaseElFormItem label="不指定人員狀態 ：" class="settings-item">
          <span data-cy="use-notspecify-view" class="content">{{
            configData.useNotSpecify ? '開啟' : '關閉'
          }}</span>
        </BaseElFormItem>

        <BaseElFormItem label="跳過選擇服務人員流程 ：" class="settings-item">
          <span data-cy="notspecify-skip-unit-view" class="content">{{
            configData.notSpecifySkipUnit ? '開啟' : '關閉'
          }}</span>
        </BaseElFormItem>

        <BaseElFormItem label="不指定人員排序 ：" class="settings-item">
          <span class="content">{{
            get(noSpecifyUnitOrderOptionConfig, `${configData.notSpecifyOrderRule}.label`)
          }}</span>
        </BaseElFormItem>

        <BaseElFormItem label="不指定人員名稱 ：" class="settings-item">
          <span data-cy="notspecify-name-view" class="content">{{
            configData.notSpecifyName !== null ? configData.notSpecifyName : '尚未設定'
          }}</span>
        </BaseElFormItem>

        <BaseElFormItem label="不指定人員照片  ：" class="settings-item">
          <span data-cy="notspecify-img-view" class="content">{{
            configData.NotSpecifyImage ? '已設置' : '尚未設定'
          }}</span>
        </BaseElFormItem>

        <BaseElFormItem label="不指定人員綁定  ：" class="settings-item">
          <span
            data-cy="notspecify-unit-view"
            class="content"
            style="width: 400px; word-wrap: break-word"
          >
            {{ notSpecifyUnits }}
          </span>
        </BaseElFormItem>

        <BaseElFormItem label="同時空檔人員分配規則  ：" class="settings-item">
          <span data-cy="notspecify-rule-view" class="content">{{
            configData.notSpecifyRule ? notSpecifyRules[configData.notSpecifyRule].name : '尚未設定'
          }}</span>
        </BaseElFormItem>
      </BaseElForm>
    </div>

    <EditReservationNotspecifySettingsModal
      v-if="modal.edit"
      data-testid="edit-reservation-notspecify-settings-modal"
      :configData="configData"
      @close="modal.edit = false"
      @refresh="$emit('refresh')"
    />
  </section>
</template>

<script>
import { computed, defineComponent, reactive } from 'vue'
import { noSpecifyUnitOrderOptionConfig } from '@/config/reservation'
import { get } from 'lodash'
import EditReservationNotspecifySettingsModal from './EditReservationNotspecifySettingsModal.vue'

export default defineComponent({
  name: 'ReservationNotspecifySettings',
  components: {
    EditReservationNotspecifySettingsModal,
  },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  setup (props) {
    const modal = reactive({ edit: false })

    const notSpecifyUnits = computed(() => {
      const list = []
      if (
        !get(props.configData, 'NotSpecifyUnits') ||
        !get(props.configData, 'NotSpecifyUnits.length')
      ) {
        return '尚未設定'
      }
      props.configData.NotSpecifyUnits.forEach((unit) => {
        list.push(unit.name)
      })
      return list.join('、')
    })

    const notSpecifyRules = {
      order: { id: 'order', name: '按排序大小' },
      reservation: { id: 'reservation', name: '預約數分配' },
      notSpecifyReservation: {
        id: 'notSpecifyReservation',
        name: '不指定預約數分配',
      },
      random: { id: 'random', name: '隨機' },
    }

    return { modal, noSpecifyUnitOrderOptionConfig, notSpecifyUnits, get, notSpecifyRules }
  },
})
</script>

<style lang="postcss" scoped></style>
