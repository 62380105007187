<template>
  <section class="section-block">
    <SectionTitle title="預約確認提示設定" @edit="dialog.edit = true" />
    <BaseElForm label-position="left" label-width="250px">
      <BaseElFormItem label="是否開啟預約確認提示：">
        <span class="text-info">{{ configData.enable ? '開啟' : '關閉' }}</span>
      </BaseElFormItem>
      <BaseElFormItem label="標題名稱：">
        <span class="text-info">{{ configData.title || '尚未設定' }}</span>
      </BaseElFormItem>
      <BaseElFormItem label="內容：">
        <span class="text-info">{{ configData.content ? '已設置' : '尚未設定' }}</span>
      </BaseElFormItem>
    </BaseElForm>
    <EditOrderConfirmNoticeSettingsDialog
      v-if="dialog.edit"
      :configData="configData"
      @refresh="refresh"
      @close="dialog.edit = false"
    />
  </section>
</template>

<script>
import EditOrderConfirmNoticeSettingsDialog from './EditOrderConfirmNoticeSettingsDialog.vue'
import { GetAppointmentCreatePromptPage } from '@/api/reservation'
import { mapGetters } from 'vuex'

export default {
  name: 'OrderConfirmNoticeSettings',
  components: { EditOrderConfirmNoticeSettingsDialog },
  data: () => ({
    configData: {},
    dialog: {
      edit: false,
    },
  }),
  computed: {
    ...mapGetters(['shop']),
  },
  async mounted () {
    await this.refresh()
  },
  methods: {
    async refresh () {
      await this.getAppointmentCreatePromptPage()
    },
    async getAppointmentCreatePromptPage () {
      const [res, err] = await GetAppointmentCreatePromptPage({
        shopId: this.shop,
      })
      if (err) {
        this.$message.error(err)
        return
      }
      this.configData = res
    },
  },
}
</script>

<style lang="postcss" scoped>
</style>
