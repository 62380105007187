<template>
  <el-dialog
    data-testid="edit-reservation-notice-settings-modal"
    title="編輯預約須知設定"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <AiHintBlock
      :hintLoading="hintLoading"
      @confirm="hintConfirm"
    />
    <BaseElForm
      ref="form"
      v-loading="hintLoading"
      label-position="top"
      :model="formData"
      :rules="formRules"
    >
      <BaseElFormItem label="是否開啟預約須知：" prop="enable">
        <BaseElSwitch
          v-model="formData.enable"
          testName="formData_enable"
          active-text="開啟"
          inactive-text="關閉"
          @change="enableChange"
        />
      </BaseElFormItem>
      <BaseElFormItem ref="title" label="標題名稱" prop="title">
        <BaseElInput
          v-model="formData.title"
          testName="title"
          placeholder="請輸入"
          :disabled="!formData.enable"
          maxlength="12"
          show-word-limit
        />
      </BaseElFormItem>
      <BaseElFormItem ref="images" label="圖片（至多三張）" prop="images">
        <div class="flex gap-10 flex-wrap">
          <div
            v-for="(img, index) in formData.images"
            :key="img.id"
            class="img-wrapper"
            :class="{disabled: !formData.enable}"
          >
            <i
              v-if="formData.enable"
              class="el-icon-circle-close remove-btn"
              @click="removeImg(index)"
            />
            <img :src="imgUrl(img)" alt="">
          </div>
          <UploadButton
            v-if="formData.images.length < 3"
            style="width: 90px; height: 90px"
            :disabled="!formData.enable"
            :img="null"
            @change="imageChange"
          />
        </div>
      </BaseElFormItem>
      <BaseElFormItem ref="content" label="內容" prop="content">
        <div :class="{'editor-diabled': !formData.enable}">
          <quillEditor
            v-model="formData.content"
            :options="editorOption"
            :disabled="!formData.enable"
            @change="contentChange"
          />
        </div>
      </BaseElFormItem>
    </BaseElForm>
    <div slot="footer">
      <BaseElButton plain @click="$emit('close')">取消</BaseElButton>
      <BaseElButton
        ref="createBtn"
        type="primary"
        @click="handleUpdate"
      >
        確認
      </BaseElButton>
    </div>

    <ImageCropper
      v-if="dialog.cropper"
      :image="imageTemp"
      :ratio="[400, 400]"
      @close="dialog.cropper = false"
      @uploaded="getImage"
    />
  </el-dialog>
</template>

<script>
import ImageCropper from '@/components/ImageCropper.vue'
import UploadButton from '@/components/Button/UploadButton.vue'
import { UpdateAppointmentHomePage } from '@/api/reservation'
import { mapGetters } from 'vuex'
import { noEmptyRules } from '@/validation'
import formUtils from '@/utils/form'
import { getRefsVue2, imgSrc } from '@/utils/helper'
import quillMixin from '@/mixin/quill'
import { get, map, cloneDeep } from 'lodash'
import AiHintBlock from '@/components/AiHintBlock.vue'
import { useAi } from '@/use/useAi'
import { hint } from '@/config/hint'
import { defineComponent, reactive } from 'vue'

export default defineComponent({
  name: 'EditNoticeSettingsDialog',
  components: { UploadButton, ImageCropper, AiHintBlock },
  mixins: [quillMixin],
  props: {
    configData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup () {
    const { configurationHint, hintLoading, setData } = useAi()
    const formData = reactive({
      enable: false,
      images: [],
      title: '',
      content: '',
    })
    const hintConfirm = async (payload) => {
      const res = await configurationHint(get(hint, 'appointmentHomePage.key'), payload)
      if (!res) {
        hintLoading.value = false
        return
      }
      setData(formData, res)
    }
    return { hintLoading, hintConfirm, formData }
  },
  data: () => ({
    imageTemp: null,
    dialog: {
      cropper: false,
    },
    // formData: {
    //   enable: false,
    //   images: [],
    //   title: '',
    //   content: '',
    // },
  }),
  computed: {
    ...mapGetters(['shop']),
    formRules () {
      const rules = {
        title: [noEmptyRules()],
        images: [noEmptyRules()],
        content: [noEmptyRules()],
      }
      if (this.formData.images.length || !this.formData.enable) delete rules.content
      if (this.formData.content || !this.formData.enable) delete rules.images
      return rules
    },
  },
  mounted () {
    this.syncFormData()
  },
  methods: {
    enableChange (e) {
      if (!e) {
        const titleRef = getRefsVue2(this, 'title')
        if (titleRef) titleRef.clearValidate()
        const imagesRef = getRefsVue2(this, 'images')
        if (imagesRef) imagesRef.clearValidate()
        const contentRef = getRefsVue2(this, 'content')
        if (contentRef) contentRef.clearValidate()
      }
    },
    contentChange (e) {
      if (e.text === '\n') {
        this.formData.content = null
      }
      const imagesRef = getRefsVue2(this, 'images')
      if (imagesRef) imagesRef.clearValidate()
    },
    removeImg (index) {
      this.formData.images.splice(index, 1)
    },
    imgUrl (img) {
      return imgSrc(300, img)
    },
    imageChange (img) {
      this.imageTemp = img
      this.dialog.cropper = true
    },
    getImage (img) {
      this.formData.images.push(img)
      this.imageTemp = img
      this.dialog.cropper = false
      const contentRef = getRefsVue2(this, 'content')
      if (contentRef) contentRef.clearValidate()
      // this.$refs.content.clearValidate()
    },
    async handleUpdate () {
      if (!await formUtils.checkForm(this.$refs.form)) return
      if (!await this.updateAppointmentHomePage()) return
      this.$message.success('更新成功！')
      this.$emit('refresh')
      this.$emit('close')
    },
    syncFormData () {
      const config = cloneDeep(this.configData)
      this.formData.enable = config.enable
      this.formData.images = get(config, 'Images') || []
      this.formData.title = get(config, 'title') || null
      this.formData.content = get(config, 'content') || null
    },

    async updateAppointmentHomePage () {
      const form = this.formData
      const [, err] = await UpdateAppointmentHomePage({
        shopId: this.shop,
        enable: form.enable,
        title: form.title ? form.title : undefined,
        images: map(form.images, 'id'),
        content: form.content ? form.content : '',
      })
      if (err) {
        this.$message.error(err)
        return false
      }
      return true
    },
  },
})
</script>

<style lang="postcss" scoped>
.img-wrapper {
  @apply relative w-[90px] h-[90px] rounded-[4px];

  img {
    @apply rounded-[4px];
  }
}

.remove-btn {
  @apply rounded-[4px] text-white text-[20px] cursor-pointer;
  @apply flex justify-center items-center;
  @apply absolute top-0 left-0 right-0 bottom-0;
  background: rgba(0, 0, 0, 0.3);
}

.editor-diabled {
  @apply bg-info-light opacity-50 cursor-not-allowed pointer-events-none;
}

.disabled {
  @apply cursor-not-allowed bg-info opacity-50;
}
</style>
